import request from './requestWrapper';

// Types
import {FiltersPaginationType} from "../types/SharedTypes";


function getParams() {
  return request({
    url: 'VideoRecord/GetParams',
    method: 'GET',
  });
}
function getAll(pagination: FiltersPaginationType) {
  return request({
    url: 'VideoRecord/GetAll',
    method: 'POST',
    data: pagination
  });
}

export default {
  getParams, getAll
}