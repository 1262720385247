// Libraries
import React from "react";
import {Card, Col} from "antd";
import useUsers from "./useUsers";
import {PlusOutlined} from "@ant-design/icons";

// Utils
import {tableColumns} from "../../utils/tableColumns";

// Components
import TableComponent from "../subComponents/TableComponent";
import ButtonComponent from "../subComponents/ButtonComponent";
import UserDrawer from "./userDrawer/UserDrawer";


const Users = () => {
  const {
    showUserDrawer, isEditing, page, pageSize, rowCount, userList, userForm,
    setPagination, handleActionButtons, handleShowDrawer, handleSubmit
  } = useUsers();

  return (
    <Card title={"Usuarios"} bordered={false} className={'w-full'}>
      <Col className={'w-full text-right mb-5'}>
        <ButtonComponent text={'Nuevo usuario'}
                         icon={<PlusOutlined />}
                         className={'ant-btn-primary'}
                         onClick={() => handleShowDrawer(true)} />
      </Col>

      <TableComponent pageSize={pageSize}
                      currentPage={page}
                      total={rowCount}
                      scrollX={900}
                      rowKey={'userId'}
                      dataSource={userList}
                      columns={tableColumns('users', handleActionButtons)}
                      onChangePage={setPagination} />

      {showUserDrawer &&
        <UserDrawer isEditing={isEditing}
                    form={userForm}
                    handleShowDrawer={handleShowDrawer}
                    handleSubmit={handleSubmit} />
      }
    </Card>
  );
}

export default Users;