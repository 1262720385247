// Libraries
import React, {useEffect} from "react";
import {Form} from "antd";
import {useSearchParams} from "react-router-dom";

// Store
import {useAppDispatch} from "../../../store/store";
import {login} from "../../../store/redux/actions/accountActions";

// Types
import {AccountType} from "../../../types/AccountTypes";

// Components
import {SUCCESS_MODAL} from "../../subComponents/responseModals";


const useLogin = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const confirm = searchParams.get('confirm');

    if (confirm === 'true') {
      SUCCESS_MODAL('Tu cuenta ha sido confirmada correctamente. Ahora puedes iniciar sesión.');
      setSearchParams((params) => {
        params.delete('confirm');
        return params;
      });
    }
  }, []);

  const handleLogin = (values: AccountType) => {
    dispatch(login(values));
  };

  return {
    form,
    handleLogin,
  }
}

export default useLogin;
