// Constants
import {videoRecordTypes} from '../types';
import {VideoRecordReducerType} from "../../../types/VideoRecordTypes";

const initialState = {
  params: {
    roles: []
  },
  videoRecordsResponse: {
    page: 1, pageSize: 10, rowCount: 0, collection: []
  }
};

const videoRecordReducer = (state = initialState, action: VideoRecordReducerType) => {
  switch (action.type) {
    case videoRecordTypes.GET_VIDEO_RECORD_PARAMS:
      return {
        ...state,
        params: action.params,
      };
    case videoRecordTypes.GET_VIDEO_RECORDS:
      return {
        ...state,
        videoRecordsResponse: action.videoRecordsResponse
      };
    default:
      return state;
  }
};

export default videoRecordReducer;