// Libraries
import React from "react";
import {Form} from "antd";

// Store
import {useAppDispatch} from "../../../store/store";

// Types
import {AccountType} from "../../../types/AccountTypes";
import {register} from "../../../store/redux/actions/accountActions";

const useRegister = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const handleRegister = (values: AccountType) => {
    dispatch(register(values));
  };

  return {
    form,
    handleRegister,
  }
}

export default useRegister;