import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {UserOutlined, VideoCameraOutlined} from '@ant-design/icons';

// Store
import {useAppDispatch} from '../../../store/store';
import * as generalSelectors from '../../../store/redux/selectors/generalSelectors';
import {setCurrentRoute} from '../../../store/redux/actions/generalActions';

// Utils and Constants
import {routes} from '../../../utils/routes';
import {
  clearStorage,
  getLocalStorageItem,
  getWindowInformation,
  reloadWindow, ROLES,
  useScreenSizeValidator
} from '../../../utils/generalUtils';


const Navbar = () => {
  const dispatch = useAppDispatch();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const navigate = useNavigate();
  const isSmallWindow = useScreenSizeValidator('(max-width: 767px)');
  let currentRoute = useSelector(generalSelectors.currentRouteSelector)!;
  const secondSlashIndex = currentRoute.indexOf('/', 1);
  const role = getLocalStorageItem('render_role') ?? '';
  const userName = getLocalStorageItem('user_name') ?? '';
  const userNameAcronym = userName.split(/\s/)
    .reduce((response, word, index) =>
      response += index < 2 ? word.slice(0,1) : '', ''
    );
  currentRoute = secondSlashIndex === -1 ? currentRoute : currentRoute.substring(0, secondSlashIndex);

  useEffect(() => {
    const { pathname } = getWindowInformation();

    dispatch(setCurrentRoute(pathname));
  }, []);

  const handleRouteSelector = (route: string) => {
    setDrawerVisible(false);

    if(route === "logout"){
      handleLogout();
    } else {
      dispatch(setCurrentRoute(route));
      navigate(route, { replace: false });
    }
  };

  const handleLogout = () => {
    dispatch(setCurrentRoute(routes.root));
    clearStorage();
    reloadWindow();
  };

  const setNavItems = () => {
    const navItems = [];

    if (role === ROLES["1"]) {
      navItems.push({ key: routes.users.root, icon: <UserOutlined />, label: 'Usuarios' });
    }
    navItems.push({ key: routes.videoRecords.root, icon: <VideoCameraOutlined />, label: 'Videos' });

    if(!isSmallWindow) {
      navItems.push({ key: 'settings', label:
          <span className={'grid content-center text-center text-white h-10 w-10 bg-grey-blue-500' +
            ' rounded-full p-2 border border-aquamarine-100 cursor-pointer hover:text-aquamarine-100'}>
          {userNameAcronym}
        </span>,
        children: [{ key: 'logout', label: 'Cerrar sesión' }],
      });
    }

    return navItems;
  };

  return {
    drawerVisible,
    isSmallWindow,
    currentRoute,
    handleRouteSelector,
    setNavItems,
    setDrawerVisible,
  };
}

export default Navbar;
