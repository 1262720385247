// Libraries
import React from "react";
import {Form} from "antd";

// Store
import {useAppDispatch} from "../../../store/store";
import {forgotPassword} from "../../../store/redux/actions/accountActions";

// Types
import {AccountType} from "../../../types/AccountTypes";

const useForgotPassword = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const handleForgotPassword = (values: AccountType) => {
    dispatch(forgotPassword(values));
  };

  return {
    form,
    handleForgotPassword,
  }
}

export default useForgotPassword;
