// Libraries
import React from "react";
import {Col, Drawer, Form, FormInstance, Row, Space, Switch} from "antd";
import {MailOutlined, UserOutlined} from "@ant-design/icons";
import useUserDrawer from "./useUserDrawer";

// Types
import ButtonComponent from "../../subComponents/ButtonComponent";
import FormItemComponent from "../../subComponents/FormItemComponent";
import InputComponent from "../../subComponents/InputComponent";

// Components
import SelectComponent from "../../subComponents/SelectComponent";


type PropsType = {
  isEditing: boolean;
  form: FormInstance<any>;
  handleShowDrawer: (show: boolean) => void;
  handleSubmit: () => void;
};
const UserDrawer = (props: PropsType) => {
  const {isEditing, form, handleShowDrawer, handleSubmit} = props;
  const {roles} = useUserDrawer();

  return (
    <Drawer title={isEditing ? 'Editar usuario' : 'Crear usuario'}
            width={500}
            open={true}
            closable={false}
            destroyOnClose={true}
            onClose={() => handleShowDrawer(false)}
            extra={
              <Space>
                <ButtonComponent text={'Cancelar'}
                                 onClick={() => handleShowDrawer(false)} />

                <ButtonComponent text={'Guardar'}
                                 className={'ant-btn-primary'}
                                 onClick={handleSubmit} />
              </Space>
            }
    >
      <Form layout={"vertical"} form={form}>
        <Row className={'grid grid-cols-1'}>
          {isEditing &&
            <>
              <span className={'hidden'}>
                <FormItemComponent name={'userId'}
                                   required={false}
                                   child={<Col />}
                />
              </span>

              <FormItemComponent name={'isActive'}
                                 required={true}
                                 valuePropName={'checked'}
                                 child={<Switch checkedChildren={'Activo'}
                                                unCheckedChildren={'Inactivo'}
                                                className={'w-fit'} />}
              />
            </>
          }
          <FormItemComponent name={'email'}
                             label={'Correo'}
                             required={true}
                             className={'mx-0'}
                             child={<InputComponent placeHolder={'Correo del usuario'}
                                                    disabled={isEditing}
                                                    readOnly={isEditing}
                                                    prefix={<MailOutlined />} />}
          />
          <FormItemComponent name={'name'}
                             label={'Name'}
                             required={true}
                             className={'mx-0'}
                             child={<InputComponent placeHolder={'Nombre del usuario'}
                                                    prefix={<UserOutlined />} />}
          />
          <FormItemComponent name={'roleId'}
                             label={'Rol'}
                             required={true}
                             className={'mx-0'}
                             child={<SelectComponent placeHolder={'Selecciona una opción'}
                                                     idIdentifier={'id'}
                                                     valueIdentifier={'name'}
                                                     list={roles} />
                                    }
          />
        </Row>
      </Form>
    </Drawer>
  );
}

export default UserDrawer;