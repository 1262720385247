// Libraries
import React from "react";
import {Form, DatePicker, Col, FormInstance, Divider} from "antd";
import useFiltersForm from "./useFiltersForm";

// Components and Subcomponents
import SelectComponent from "../../../subComponents/SelectComponent";
import FormItemComponent from "../../../subComponents/FormItemComponent";
import ButtonComponent from "../../../subComponents/ButtonComponent";

// Types
import {VideoRecordFiltersType} from "../../../../types/VideoRecordTypes";

// Utils
import {DATE_TIME_FORMAT, FINAL_DATETIME_FORMAT, INITIAL_DATETIME_FORMAT} from "../../../../utils/generalUtils";

const {RangePicker} = DatePicker;


type PropsType = {
  filterValues: VideoRecordFiltersType;
  formFilters: FormInstance<any>;
  onFinishFilter: (filters: VideoRecordFiltersType) => void;
}
const FiltersForm = (props: PropsType) => {
  const {formFilters} = props;
  const {users, disabledDate, handleFilters} = useFiltersForm(props);

  return (
    <Form form={formFilters}
          layout={'vertical'}
          className={'w-96 mx-2 rounded bg-white border-[1px] border-solid border-[rgba(0,0,0,0.4)] ' +
            'shadow-md p-2.5 pt-5 absolute z-50 w-fit'}>
      <Col className={`grid grid-cols-1 sm:gap-2 p-2`}>
        <FormItemComponent name={'dates'}
                           label={'Fechas de creación'}
                           required={false}
                           className={'mx-0'}
                           child={<RangePicker className={'w-full'}
                                               picker={'date'}
                                               format={['DD/MM/YYYY 00:00', 'DD/MM/YYYY 23:59']}
                                               allowClear={false}
                                               disabledDate={disabledDate} />} />
        <FormItemComponent name={'userId'}
                           label={'Usuario'}
                           required={false}
                           className={'mx-0'}
                           child={<SelectComponent idIdentifier={'customId'}
                                                   valueIdentifier={'name'}
                                                   placeHolder={'Selecciona uno'}
                                                   list={users} />} />
      </Col>


      <Divider className={'my-2'} />

      <Col md={24} xs={24} className={'text-right'}>
        <ButtonComponent text={'Buscar'}
                         className={'ant-btn-primary'}
                         onClick={handleFilters} />
      </Col>
    </Form>
  );
}

export default FiltersForm;