// Libraries
import React from "react";

// Utils
import useWavesBackground from "./useWavesBackground";


const WavesBackground = () => {
  const {isExtraSmallWindow, isSmallWindow} = useWavesBackground();

  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 1440 ${isExtraSmallWindow ? '310' : isSmallWindow ? '270' : '290'}`} className={'absolute bottom-0 w-full md:hidden opacity-30'}>
        <path id={'wave-1'} fill="#E4ECF5" fillOpacity="1" d="M0,256L80,234.7C160,213,320,171,480,160C640,149,800,171,960,170.7C1120,171,1280,149,1360,138.7L1440,128L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 1440 ${isExtraSmallWindow ? '300' : isSmallWindow ? '260' : '280'}`} className={'absolute bottom-0 w-full md:hidden opacity-30'}>
        <path id={'wave-2'} fill="#E4ECF5" fillOpacity="1" d="M0,64L80,90.7C160,117,320,171,480,186.7C640,203,800,181,960,186.7C1120,192,1280,224,1360,240L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 1440 ${isExtraSmallWindow ? '280' : isSmallWindow ? '240' : '260'}`} className={'absolute bottom-0 w-full md:hidden opacity-10'}>
        <path id={'wave-3'} fill="#E4ECF5" fillOpacity="1" d="M0,64L80,58.7C160,53,320,43,480,74.7C640,107,800,181,960,202.7C1120,224,1280,192,1360,176L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 1440 ${isExtraSmallWindow ? '300' : isSmallWindow ? '260' : '280'}`} className={'absolute bottom-0 w-full md:hidden opacity-10'}>
        <path id={'wave-4'} fill="#E4ECF5" fillOpacity="1" d="M0,128L80,117.3C160,107,320,85,480,96C640,107,800,149,960,149.3C1120,149,1280,107,1360,85.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 1440 ${isExtraSmallWindow ? '260' : isSmallWindow ? '220' : '240'}`} className={'absolute bottom-0 w-full md:hidden opacity-30'}>
        <path id={'wave-5'} fill="#E4ECF5" fillOpacity="1" d="M0,288L80,245.3C160,203,320,117,480,80C640,43,800,53,960,64C1120,75,1280,85,1360,90.7L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 1440 ${isExtraSmallWindow ? '310' : isSmallWindow ? '270' : '290'}`} className={'absolute bottom-0 w-full md:hidden opacity-25'}>
        <path id={'wave-6'} fill="#E4ECF5" fillOpacity="1" d="M0,256L80,245.3C160,235,320,213,480,224C640,235,800,277,960,288C1120,299,1280,277,1360,266.7L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 1440 ${isExtraSmallWindow ? '270' : isSmallWindow ? '230' : '250'}`} className={'absolute bottom-0 w-full md:hidden opacity-10'}>
        <path id={'wave-7'} fill="#E4ECF5" fillOpacity="1" d="M0,160L80,144C160,128,320,96,480,85.3C640,75,800,85,960,90.7C1120,96,1280,96,1360,96L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
      </svg>
    </>
  );
}

export default WavesBackground;