/* --------------- General ---------------*/
export const generalTypes = {
  IS_LOADING: 'IS_LOADING',
  SET_CURRENT_ROUTE: 'SET_CURRENT_ROUTE',
};

/* --------------- Users ---------------*/
export const userTypes = {
  GET_USER_PARAMS: 'GET_USER_PARAMS',
  GET_USERS: 'GET_USERS',
};

/* --------------- Video Records ---------------*/
export const videoRecordTypes = {
  GET_VIDEO_RECORD_PARAMS: 'GET_VIDEO_RECORD_PARAMS',
  GET_VIDEO_RECORDS: 'GET_VIDEO_RECORDS',
};
