// Libraries
import React, {ReactElement} from "react";
import {Row, Col, Form, Space, FormInstance, Divider} from "antd";

// Components
import ButtonComponent from "../subComponents/ButtonComponent";

// Constants
import geekcoreLogo from "../../assets/images/geekcore-logo.png"
import {Logo} from "../../assets/customIcons";

// Types
import {AccountType} from "../../types/AccountTypes";

// Utils
import {useScreenSizeValidator} from "../../utils/generalUtils";
import WavesBackground from "./wavesBackground/WavesBackground";


type PropsType = {
  buttonText: string;
  headerContent: ReactElement;
  formContent: ReactElement;
  footerContent: ReactElement;
  form: FormInstance<any>;
  handleSubmit: (values: AccountType) => void;
}
const Account = (props: PropsType) => {
  const isMiddleWindow = useScreenSizeValidator('(max-width: 991px)');
  const isSmallWindow = useScreenSizeValidator('(max-width: 767px)');
  const {buttonText, headerContent, form, formContent, footerContent, handleSubmit} = props;

  return (
    <Row className={'flex min-h-screen w-full'}>
      <Col lg={12} md={9} xs={24} className={'w-full bg-grey-blue-600 flex'}>
        <span className={'m-auto'}>
          <Logo width={isSmallWindow ? 150 : isMiddleWindow ? 200 : 300}
                height={isSmallWindow ? 75 : isMiddleWindow ? 150 : 200}
                fill={''} />
        </span>
      </Col>

      <Col lg={12} md={15} xs={24} className={'bg-grey-blue-600 md:bg-[#f5f5f5] p-5 md:p-0'}>
        <Form layout={"vertical"}
              className={'flex px-5 md:py-0 md:px-10 lg:px-20 md:h-full w-full rounded'}
              form={form}
              onFinish={handleSubmit}>
          <Space direction={'vertical'} className={'w-full my-auto'} size={"small"}>
            <h1 className={'text-white md:text-grey-blue-600 text-xl md:text-2xl pb-4 text-center md:text-left mb-5 md:mb-0'}>
              {headerContent}
            </h1>

            <Col className={'[&>span>div>div>div>label]:hidden ' +
              'md:[&>span>div>div>div>label]:block ' +
              '[&>span>div>div>div>div>div>span]:border-grey-blue-300 ' +
              'md:[&>span>div>div>div>div>div>span]:border-grey-blue-500 ' +
              '[&>span>div>div>div>div>div>span>input]:placeholder-grey-blue-50 ' +
              'md:[&>span>div>div>div>div>div>span>input]:placeholder-[transparent] ' +
              '[&>span>div>div>div>div>div>span]:bg-grey-blue-200 ' +
              '[&>span>div>div>div>div>div>span]:bg-opacity-10 ' +
              '[&>span>div>div>div>div>div>span]:text-white ' +
              'md:[&>span>div>div>div>div>div>span]:text-grey-blue-500 ' +
              '[&>span>div>div>div>div>div>span>span>span>svg]:fill-grey-blue-100 ' +
              'md:[&>span>div>div>div>div>div>span>span>span>svg]:fill-grey-blue-500'}>
              {formContent}
            </Col>

            <ButtonComponent text={buttonText}
                             htmlType={'submit'}
                             className={'w-full rounded-2xl md:rounded bg-aquamarine-100 md:bg-grey-blue-500 ' +
                               'text-grey-blue-500 md:text-grey-blue-100 font-bold border-aquamarine-200 border-aquamarine-aquamarine-300'} />

            <Divider className={'m-2 hidden md:block'} />

            <div className={'text-center w-full text-grey-blue-100 md:text-grey-blue-600 ' +
              '[&>div>span>div]:bg-aquamarine-100 md:[&>div>span>div]:bg-grey-blue-500'}>
              {footerContent}
            </div>
          </ Space>
        </ Form>

        <Col md={24} xs={24} className={'flex text-center text-grey-blue-600 font-bold absolute bottom-0 left-0 w-full'}>
          <WavesBackground />

          <Col className={'absolute bottom-4 w-full md:bg-none text-black'}>
            Powered by <a href={"https://geekcore.co"} target={"_blank"}>
              <img alt={"Geekcore"} src={geekcoreLogo} className={'inline-flex'} width={80} />
            </a>
          </Col>
        </Col>
      </Col>
    </Row>
  );
}

export default Account;