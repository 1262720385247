// Libraries
import React, {useState} from "react";
import {Form} from "antd";

// Types
import {VideoRecordFiltersType} from "../../../types/VideoRecordTypes";


type PropsType = {
  onFinishFilter: (filters: VideoRecordFiltersType) => void;
}
const useFilters = (props: PropsType) => {
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [formFilters] = Form.useForm();
  const {onFinishFilter} = props;

  const handleClearFilters = () => {
    onFinishFilter({});
    formFilters.resetFields();
  }

  return {
    openFilters,
    formFilters,
    setOpenFilters,
    handleClearFilters,
  }
}

export default useFilters;