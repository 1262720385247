// Libraries
import React from 'react';
import {Spin} from "antd";
import {Logo} from "../../../assets/customIcons";

const Loading = () => {
  return (
    <Spin spinning={true}
          size={'large'}
          fullscreen
          tip={<Logo width={150} height={75} fill={''} />}
          className={'bg-grey-blue-600 bg-opacity-70'} />
  );
}

export default Loading;
