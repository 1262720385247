// Libraries
import React from "react";
import {Col, Space} from "antd";
import useFilters from "./useFilters";
import {ClearOutlined, FilterOutlined} from "@ant-design/icons";

// Components
import ButtonComponent from "../../subComponents/ButtonComponent";
import FilterTags from "../filters/filterTags/FilterTags";
import FiltersForm from "../filters/filtersForm/FiltersForm";

// Types
import {VideoRecordFiltersType} from "../../../types/VideoRecordTypes";


type PropsType = {
  filterValues: VideoRecordFiltersType;
  onFinishFilter: (filters: VideoRecordFiltersType) => void;
}
const Filters = (props: PropsType) => {
  const {openFilters, formFilters, setOpenFilters, handleClearFilters} = useFilters(props);
  const { filterValues, onFinishFilter } = props;

  return (
    <Col className={'w-full block'}>
      <Space className={'p-2'}>
        <ButtonComponent className={`ant-btn-primary ${openFilters && 'z-50'}`}
                         text={'Filtros'}
                         onClick={() => setOpenFilters(true)}
                         icon={<FilterOutlined />} />

        <ButtonComponent text={'Borrar Filtros'}
                         onClick={handleClearFilters}
                         icon={<ClearOutlined />} />
      </Space>

      {openFilters &&
        <Col>
          <Col className={"bg-grey-blue-600 bg-opacity-50 w-full h-full fixed z-40 left-0 top-0"}
               onClick={() => setOpenFilters(false)} />
          <FiltersForm formFilters={formFilters}
                       filterValues={filterValues}
                       onFinishFilter={(filters) => {
                         setOpenFilters(false);
                         onFinishFilter(filters);
                       }} />
        </Col>
      }

      <Col className={'p-2'}>
        <FilterTags filterValues={filterValues} />
      </Col>
    </Col>
  );
}

export default Filters;