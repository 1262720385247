// Libraries
import React from "react";
import {Row, Tag} from "antd";
import useFilterTags from "./useFilterTags";

// Types
import {VideoRecordFiltersType} from "../../../../types/VideoRecordTypes";


type PropsType = {
  filterValues: VideoRecordFiltersType;
}
const FilterTags = (props: PropsType) => {
  const {tagList} = useFilterTags(props);

  return (
    <Row>
      {tagList.map((tag, tagKey) =>
        <Tag key={tagKey} className={'my-px'}>
          <b>{tag.name}: </b> {tag.value}
        </Tag>
      )}
    </Row>
  );
}

export default FilterTags;