import request from './requestWrapper';

// Types
import {PaginationType} from "../types/SharedTypes";
import {UserType} from "../types/UserTypes";


function getParams() {
  return request({
    url: 'User/GetParams',
    method: 'GET',
  });
}
function getAll(pagination: PaginationType) {
  return request({
    url: 'User/GetAll',
    method: 'POST',
    data: pagination
  });
}
function add(user: UserType) {
  return request({
    url: 'User/Add',
    method: 'POST',
    data: user
  });
}
function update(user: UserType) {
  return request({
    url: 'User/Update',
    method: 'PUT',
    data: user
  });
}

export default {
  getParams, getAll, add, update
}