// Libraries
import React, {useEffect} from "react";

// Utils
import {useScreenSizeValidator} from "../../../utils/generalUtils";


const WavesBackground = () => {
  const isExtraSmallWindow = useScreenSizeValidator('(max-width: 500px)');
  const isSmallWindow = useScreenSizeValidator('(min-width: 600px)');

  useEffect(() => {
    window.onresize = getSvgHeight;
    window.onload = getSvgHeight;
  }, []);

  const getSvgHeight = () => {
    let svg1 = document.getElementById('wave-1')!;
    let svg2 = document.getElementById('wave-1')!;
    let svg3 = document.getElementById('wave-1')!;
    let svg4 = document.getElementById('wave-1')!;
    let svg5 = document.getElementById('wave-1')!;
    let svg6 = document.getElementById('wave-1')!;
    let svg7 = document.getElementById('wave-1')!;

    svg1.style.transform = `scale(1, 0.8${window.innerWidth})`;
    svg2.style.transform = `scale(1, 0.8${window.innerWidth})`;
    svg3.style.transform = `scale(1, 0.8${window.innerWidth})`;
    svg4.style.transform = `scale(1, 0.8${window.innerWidth})`;
    svg5.style.transform = `scale(1, 0.8${window.innerWidth})`;
    svg6.style.transform = `scale(1, 0.8${window.innerWidth})`;
    svg7.style.transform = `scale(1, 0.8${window.innerWidth})`;
  };

  return {
    isExtraSmallWindow,
    isSmallWindow
  }
}

export default WavesBackground;