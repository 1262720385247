// Libraries
import {Navigate, Route, Routes} from 'react-router-dom';
import {Layout} from 'antd';
import useRouter from "./useRouter";

// Components
import Login from "../../session/login/Login";
import Register from "../../session/register/Register";
import ForgotPassword from "../../session/forgotPassword/ForgotPassword";
import ResetPassword from "../../session/resetPassword/ResetPassword";
import Users from "../../users/Users";
import VideoRecords from "../../videoRecords/VideoRecords";

// Utils and Constants
import {routes} from '../../../utils/routes';
import {ROLES} from "../../../utils/generalUtils";

const {Content} = Layout;


function Router() {
  const {isLoggedIn, isSmallWindow, role} = useRouter();

  return (
    <Content className={`flex h-full ${isLoggedIn ? isSmallWindow ? 'py-6 px-12' : 'py-6 px-20' : ''}`}>
      <Routes>
        {isLoggedIn
          ?
          <>
            {role === ROLES["1"] &&
              <Route path={routes.users.root} element={<Users />} />
            }
            <Route path={routes.videoRecords.root} element={<VideoRecords />} />

            <Route path={'*'} element={<Navigate to={role === ROLES["1"] ? routes.users.root : routes.videoRecords.root} />} />
          </>
          :
          <>
            <Route path={routes.account.login} element={<Login />} />
            <Route path={routes.account.register} element={<Register />} />
            <Route path={routes.account.forgotPassword} element={<ForgotPassword />} />
            <Route path={routes.account.resetPassword} element={<ResetPassword />} />

            <Route path={'*'} element={<Navigate to={routes.account.login} />} />
          </>
        }
      </Routes>
    </Content>
  );
}

export default Router;