import request from './requestWrapper';

// Types
import {AccountType} from "../types/AccountTypes";


function confirmEmail(data: AccountType) {
  return request({
    url: 'Account/ConfirmEmail',
    method: 'GET',
  });
}
function login(data: AccountType) {
  return request({
    url: 'Account/Login',
    method: 'POST',
    data: data
  });
}
function register(data: AccountType) {
  return request({
    url: 'Account/Register',
    method: 'POST',
    data: data
  });
}
function forgotPassword(data: AccountType) {
  return request({
    url: 'Account/ForgotPassword',
    method: 'POST',
    data: data
  });
}
function resetPassword(data: AccountType) {
  return request({
    url: 'Account/ResetPassword',
    method: 'POST',
    data: data
  });
}

export default {
  confirmEmail, login, register, forgotPassword, resetPassword
}