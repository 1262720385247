// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import videoRecordServices from '../../../api/videoRecordServices';
import {videoRecordTypes} from "../types";

// Components and Subcomponents
import {ERROR_MODAL} from '../../../components/subComponents/responseModals';


// Types
import {FiltersPaginationType} from "../../../types/SharedTypes";


export const getParams = (pagination: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  videoRecordServices.getParams()
    .then((response) => {
      dispatch({
        type: videoRecordTypes.GET_VIDEO_RECORD_PARAMS,
        params: response.data
      });
      dispatch(getAll(pagination));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const getAll = (pagination: FiltersPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  videoRecordServices.getAll(pagination)
    .then((response) => {
      dispatch(setLoading(false));
      dispatch({
        type: videoRecordTypes.GET_VIDEO_RECORDS,
        videoRecordsResponse: response.data
      });
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}