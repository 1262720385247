// Libraries
import {combineReducers} from 'redux';

// Reducers
import generalReducer from './generalReducer';
import accountReducer from './accountReducer';
import userReducer from "./userReducer";
import videoRecordReducer from "./videoRecordReducer";

export default combineReducers({
  generalReducer: generalReducer,
  accountReducer: accountReducer,
  userReducer: userReducer,
  videoRecordReducer: videoRecordReducer
});
