// Libraries
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Divider, Form, message, Space} from "antd";
import {EditOutlined} from "@ant-design/icons";

// Store
import {useAppDispatch} from "../../store/store";
import {add, getParams, update} from "../../store/redux/actions/userActions";
import * as userSelectors from '../../store/redux/selectors/userSelectors';

// Types
import {PaginationType} from "../../types/SharedTypes";
import {UserType} from "../../types/UserTypes";


const useUsers = () => {
  const dispatch = useAppDispatch();
  const [showUserDrawer, setShowUserDrawer] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [userList, setUserList] = useState<UserType[]>([]);
  const [pagination, setPagination] = useState<PaginationType>({
    page: 1,
    pageSize: 10,
  });
  const { roles } = useSelector(userSelectors.paramsSelector)!;
  const { page, pageSize, rowCount, collection } = useSelector(userSelectors.usersResponseSelector)!;
  const [userForm] = Form.useForm<UserType>();

  useEffect(() => {
    dispatch(getParams(pagination));
  }, []);

  useEffect(() => {
    const newUserList: UserType[] = [];

    collection.map((user: UserType) => {
      newUserList.push({
        ...user,
        roleName: roles.find(role => role.id === user.roleId)!.name
      })
    });

    setUserList(newUserList);
  }, [collection]);

  const handleShowDrawer = (showDrawer: boolean, userToEdit?: UserType) => {
    setShowUserDrawer(showDrawer);
    if (showDrawer && userToEdit) {
      setIsEditing(true);
      userForm.setFieldsValue({
        ...userToEdit
      });
    } else {
      setIsEditing(false);
      userForm.resetFields();
    }
  };

  const handleActionButtons = (user: UserType) => {
    return (
      <Space split={<Divider type={'vertical'} />} size={'small'}>
        <span className={'cursor-pointer hover:text-primary'}
              title={'Editar usuario'}
              onClick={() => handleShowDrawer(true, user)}>
          <EditOutlined />
        </span>
      </Space>
    )
  };

  const handleSubmit = () => {
    userForm.validateFields()
      .then((values) => {
        console.log(values)
        if (!isEditing) {
          values.isActive = true;
        }
        dispatch(isEditing ? update(values) : add(values));
        handleShowDrawer(false);
      })
      .catch(() => {
        message.error('Hay campos sin llenar').then();
      })
  };

  return {
    isEditing,
    showUserDrawer,
    page,
    pageSize,
    rowCount,
    userList,
    userForm,
    setPagination,
    handleActionButtons,
    handleShowDrawer,
    handleSubmit,
  }
}

export default useUsers;