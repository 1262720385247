// Libraries
import React from 'react';
import {Select} from 'antd';

// Utils
import {removeDiacritics} from "../../utils/generalUtils";


type SelectComponentType = {
  allowClear?: boolean;
  mode?: "multiple" | "tags" | undefined;
  placeHolder?: string;
  className?: string;
  idIdentifier: string;
  valueIdentifier: string;
  value?: any;
  list: any[];
  onChange?: (e: string) => void;
};
const SelectComponent = (props: SelectComponentType) => {
  const {allowClear, mode, placeHolder, className, idIdentifier, valueIdentifier, value, list, onChange} = props;

  return (
    <Select allowClear={allowClear} placeholder={placeHolder} value={value}
            optionFilterProp={"children"} mode={mode}
            className={'appearance-none block w-full [&>div]:min-h-[36px] [&>div]:px-3 [&>div]:py-0 [&>div]:border ' +
              '[&>div]:shadow-none [&>div]:border-grey-300 [&>div]:rounded-md [&>div>span]:last:leading-4 [&>*]:sm:text-sm ' +
              className
            }
            filterOption={(input, option) => {
              return removeDiacritics(option!.title!.toLowerCase()).includes(removeDiacritics(input.toLowerCase()))
            }}
            onChange={(e) => onChange ? onChange(e) : null}>
      {list.map(item =>
        <Select.Option key={item[idIdentifier]} value={item[idIdentifier]} title={item[valueIdentifier]}>
          {item[valueIdentifier]}
        </Select.Option>
      )}
    </Select>
  );
}

export default SelectComponent;
