// Libraries
import React from "react";
import {UserOutlined} from '@ant-design/icons';
import useForgotPassword from "./useForgotPassword";

// Components
import InputComponent from "../../subComponents/InputComponent";
import FormItemComponent from "../../subComponents/FormItemComponent";
import Account from "../Account";

// Utils
import {routes} from "../../../utils/routes";


const ForgotPassword = () => {
  const {form, handleForgotPassword} = useForgotPassword();

  return (
    <Account buttonText={'Recuperar contraseña'}
             headerContent={
               <span>
                 Recupera tu contraseña de <span className={'font-bold text-aquamarine-100 md:text-grey-blue-600'}>Bday</span>
               </span>
             }
             form={form}
             formContent={
               <FormItemComponent name={'email'}
                                  label={'Correo'}
                                  required={true}
                                  className={'mx-0'}
                                  child={<InputComponent placeHolder={'Correo'}
                                                         prefix={<UserOutlined />} />}
               />
             }
             footerContent={
               <>
                 {'¿Ya recuerdas tu contraseña? '}
                 <a href={routes.account.login}
                    className={'text-secondaryLight font-bold hover:underline'}>
                   Inicia sesión
                 </a>
               </>
             }
             handleSubmit={handleForgotPassword} />
  );
}

export default ForgotPassword;