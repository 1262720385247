// Libraries
import {useMediaQuery} from 'react-responsive';
import dayjs from "dayjs";

export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const INITIAL_DATETIME_FORMAT = 'YYYY-MM-DDT00:00:00';
export const FINAL_DATETIME_FORMAT = 'YYYY-MM-DDT23:59:59';

export const ROLES = {
  '1': 'Administrador',
  '2': 'Colaborador',
};

export const isEmpty = (element: string | object | null | undefined) => {
  return element === undefined || element === 'undefined'
    || element === null || element === 'null' || element === ''
    || (Array.isArray(element) && element.length === 0)
    || (Object.keys(element).length === 0);
};

export const isSignedIn = () => {
  const token = getLocalStorageItem('access_token');
  const role = getLocalStorageItem('render_role');
  const name = getLocalStorageItem('user_name');
  const expiredOn = getLocalStorageItem('expires_on');
  const incorrectInfo = isEmpty(token) || isEmpty(role) || isEmpty(name) || isEmpty(expiredOn);
  const tokenExpired = new Date() > new Date(expiredOn!);

  if(incorrectInfo || tokenExpired) clearStorage();

  return !incorrectInfo && !tokenExpired;
};

/* --------------------- Storage ----------------------- */
export const setSessionStorageItem = (fieldName: string, value: string) => {
  sessionStorage.setItem(fieldName, value);
};
export const setLocalStorageItem = (fieldName: string, value: string) => {
  localStorage.setItem(fieldName, value);
};
export const getSessionStorageItem = (fieldName: string) => {
  return sessionStorage.getItem(fieldName);
};
export const getLocalStorageItem = (fieldName: string) => {
  return localStorage.getItem(fieldName);
};
export const clearStorage = () => {
  sessionStorage.clear();
  localStorage.clear();
};

/* --------------------- Window Actions ----------------------- */
export const getWindowInformation = () => {
  const pathname = window.location.pathname;
  const search = window.location.search;

  return { pathname, search };
};
export const reloadWindow = () => {
  window.location.reload();
};
export const redirectWindow = (pathname: string) => {
  window.location.href = pathname;
};
export const useScreenSizeValidator = (sizeValidator: string) => {
  return useMediaQuery({ query: sizeValidator });
};

/* ----------------------- Currency ------------------------ */
export function currencyFormat(value?: string){
  const text = `${value}`
    .replace('.', ',')
    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.');

  return `${text}`;
}
export function currencyToNumber(value: string){
  const splitValue = value.split(',');
  let newValue;
  if(splitValue.length === 1){
    newValue = parseFloat(value);
  } else {
    newValue = parseFloat(splitValue[0].replaceAll('.', '') + '.' + splitValue[1]);
  }

  return newValue;
}

/* --------------------------- Text -------------------------- */
export const removeDiacritics = (text: string) => {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const getDateFormat = (date: string, format: string) => {
  return dayjs(date).format(format);
};
