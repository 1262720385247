// Libraries
import React from "react";
import {LockOutlined, MailOutlined} from '@ant-design/icons';
import {Divider, Space} from "antd";
import useLogin from "./useLogin";

// Components
import InputComponent from "../../subComponents/InputComponent";
import FormItemComponent from "../../subComponents/FormItemComponent";
import Account from "../Account";

// Utils
import {routes} from "../../../utils/routes";


const Login = () => {
  const {form, handleLogin} = useLogin();

  return (
    <Account buttonText={'Ingresar'}
             headerContent={
               <span>
                 Inicia sesión en <span className={'font-bold text-aquamarine-100 md:text-grey-blue-600'}>Bday</span>
               </span>
             }
             form={form}
             formContent={
               <span>
                 <FormItemComponent name={'email'}
                                    label={'Correo'}
                                    required={true}
                                    className={'mx-0'}
                                    child={<InputComponent placeHolder={'Correo'}
                                                           prefix={<MailOutlined />} />}
                 />

                 <FormItemComponent name={'password'}
                                    label={'Contraseña'}
                                    required={true}
                               child={<InputComponent type={'password'}
                                                      placeHolder={'Contraseña'}
                                                      prefix={<LockOutlined />} />}
                 />
               </span>
            }
            footerContent={
              <Space direction={'horizontal'} size={'large'} split={<Divider type="vertical" />}>
                <a href={routes.account.forgotPassword}
                   className={'text-secondaryLight font-bold hover:underline'}>
                  Olvidé mi contraseña
                </a>

                <a href={routes.account.register}
                   className={'text-secondaryLight font-bold hover:underline'}>
                  Quiero registrarme
                </a>
              </Space>
            }
            handleSubmit={handleLogin} />
  );
}

export default Login;