// Libraries
import React from "react";
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import useRegister from "./useRegister";

// Components
import InputComponent from "../../subComponents/InputComponent";
import FormItemComponent from "../../subComponents/FormItemComponent";
import Account from "../Account";

// Utils
import {routes} from "../../../utils/routes";


const Register = () => {
  const {form, handleRegister} = useRegister();
  const {getFieldValue} = form;

  return (
    <Account buttonText={'Registrar'}
             headerContent={
               <span>
                 Regístrate en <span className={'font-bold text-aquamarine-100 md:text-grey-blue-600'}>Bday</span>
               </span>
             }
             form={form}
             formContent={
               <span>
                 <FormItemComponent name={'email'}
                                    label={'Correo'}
                                    required={true}
                                    className={'mx-0'}
                                    child={<InputComponent placeHolder={'Digita tu correo'}
                                                           prefix={<UserOutlined />} />}
                 />

                 <FormItemComponent name={'password'}
                                    label={'Contraseña'}
                                    required={true}
                                    child={<InputComponent type={'password'}
                                                           placeHolder={'Digita tu contraseña'}
                                                           prefix={<LockOutlined />} />}
                 />

                 <FormItemComponent name={'confirmPassword'}
                                    label={'Confirmación de contraseña'}
                                    required={true}
                                    dependencies={['password']}
                                    newRules={[
                                      () => ({
                                        validator(_: any, value: any) {
                                          if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(new Error('Las contraseñas no coinciden'));
                                        },
                                      }),
                                    ]}
                                    child={<InputComponent type={'password'}
                                                           placeHolder={'Confirma la contraseña'}
                                                           prefix={<LockOutlined />} />}
                 />
               </span>
             }
             footerContent={
               <>
                 {'¿Ya tienes una cuenta? '}
                 <a href={routes.account.login}
                    className={'text-secondaryLight font-bold hover:underline'}>
                   Inicia sesión
                 </a>
               </>
             }
             handleSubmit={handleRegister} />
  );
}

export default Register;