// Libraries
import React from "react";
import {Form} from "antd";
import {useParams} from "react-router-dom";

// Store
import {useAppDispatch} from "../../../store/store";
import {resetPassword} from "../../../store/redux/actions/accountActions";

// Types
import {AccountType} from "../../../types/AccountTypes";

const useResetPassword = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const {userId, code} = useParams();

  const handleResetPassword = (values: AccountType) => {
    values.userId = userId;
    values.code = code;
    dispatch(resetPassword(values));
  };

  return {
    form,
    handleResetPassword,
  }
}

export default useResetPassword;
