// Types
import {UserType} from "../types/UserTypes";
import dayjs from "dayjs";

// Utils
import {DATE_TIME_FORMAT} from "./generalUtils";

const getYear = (date: string) => {
  return new Date(date).getFullYear() === 1 ? '-' : dayjs(date).format(DATE_TIME_FORMAT);
}

export const tableColumns = (section: string, actionButtons?: (object: any) => any) => {
  switch (section){
    case 'users':
      return [
        {
          title: <div className={'text-center'}>Nombre</div>,
          dataIndex: 'name',
          key: 'name',
          width: '150px',
          ellipsis: true,
          render: (name: string) => <div className={'text-center'}>{name}</div>
        },
        {
          title: <div className={'text-center'}>Correo</div>,
          dataIndex: 'email',
          key: 'email',
          width: '150px',
          ellipsis: true,
          render: (email: string) => <div className={'text-center'}>{email}</div>
        },
        {
          title: <div className={'text-center'}>Rol</div>,
          dataIndex: 'roleName',
          key: 'roleName',
          width: '100px',
          render: (roleName: number) => <div className={'text-center'}>{roleName}</div>
        },
        {
          title: <div className={'text-center'}>Activo</div>,
          dataIndex: 'isActive',
          key: 'isActive',
          width: '50px',
          render: (isActive: boolean) => <div className={'text-center'}>{isActive ? 'SI' : 'NO'}</div>
        },
        {
          title: <div className={'text-center'}>Acciones</div>,
          dataIndex: 'actions',
          key: 'actions',
          width: '40px',
          fixed: 'right',
          render: (value: any, student: UserType) => <div className={'text-center'}>{actionButtons!(student)}</div>
        },
      ];
    case 'videoRecords':
      return [
        {
          title: <div className={'text-center'}>Nombre</div>,
          dataIndex: 'videoName',
          key: 'videoName',
          width: '100px',
          ellipsis: true,
          render: (name: string) => <div className={'text-center'}>{name}</div>
        },
        {
          title: <div className={'text-center'}>Fecha de creación</div>,
          dataIndex: 'videoCreationDateTime',
          key: 'videoCreationDateTime',
          width: '150px',
          ellipsis: true,
          render: (date: string) => <div className={'text-center'}>{dayjs(date).format(DATE_TIME_FORMAT)}</div>
        },
        {
          title: <div className={'text-center'}>Fecha de envío</div>,
          dataIndex: 'videoSharingDateTime',
          key: 'videoSharingDateTime',
          width: '150px',
          ellipsis: true,
          render: (date: string) => <div className={'text-center'}>{getYear(date)}</div>
        },
        {
          title: <div className={'text-center'}>Usuario</div>,
          dataIndex: 'userName',
          key: 'userName',
          width: '100px',
          render: (userName: number) => <div className={'text-center'}>{userName}</div>
        }
      ];
    default:
      return [];
  }
};