export const routes = {
  root: '/',
  account: {
    login: '/sesion',
    register: '/registro',
    forgotPassword: '/recuperar-contrasena',
    resetPassword: '/cambio-de-contrasena/:userId/:code',
  },
  users: {
    root: '/usuarios',
  },
  videoRecords: {
    root: '/videos',
  }
};