// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import accountServices from '../../../api/accountServices';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL, SUCCESS_MODAL_ON_OK} from '../../../components/subComponents/responseModals';

// Utils
import {redirectWindow, reloadWindow, setLocalStorageItem} from "../../../utils/generalUtils";
import {routes} from "../../../utils/routes";

// Types
import {AccountType} from "../../../types/AccountTypes";


export const confirmEmail = (data: AccountType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  accountServices.confirmEmail(data)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL(response.data);
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const login = (data: AccountType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  accountServices.login(data)
    .then((response) => {
      Object.keys(response.data).map(key => {
        setLocalStorageItem(key, response.data[key]);
      });
      reloadWindow();
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const register = (data: AccountType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  accountServices.register(data)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL_ON_OK(response.data, () => redirectWindow(routes.account.login));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const forgotPassword = (data: AccountType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  accountServices.forgotPassword(data)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL_ON_OK(response.data, () => redirectWindow(routes.account.login));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const resetPassword = (data: AccountType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  accountServices.resetPassword(data)
    .then((response) => {
      dispatch(setLoading(false));
      SUCCESS_MODAL_ON_OK(response.data, () => redirectWindow(routes.account.login));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}