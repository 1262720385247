// Libraries
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {RangePickerProps} from "antd/es/date-picker";
import dayjs from "dayjs";
import {FormInstance} from "antd";

// Store
import * as videoRecordSelectors from '../../../../store/redux/selectors/videoRecordSelectors';

// Types
import {VideoRecordFiltersType} from "../../../../types/VideoRecordTypes";

// Utils
import {FINAL_DATETIME_FORMAT, INITIAL_DATETIME_FORMAT} from "../../../../utils/generalUtils";


type PropsType = {
  filterValues: VideoRecordFiltersType;
  formFilters: FormInstance<any>;
  onFinishFilter: (filters: VideoRecordFiltersType) => void;
}
const useFiltersForm = (props: PropsType) => {
  const { users } = useSelector(videoRecordSelectors.paramsSelector)!;
  const {filterValues, formFilters, onFinishFilter} = props;

  useEffect(() => {
    formFilters.setFieldValue('userId', filterValues.userId);
    formFilters.setFieldValue('initialDate', filterValues.initialDate);
    formFilters.setFieldValue('finalDate', filterValues.finalDate);
  }, []);

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    const tomorrow = dayjs().add(1, 'day').startOf('day');

    return current && (current >= tomorrow);
  };

  const handleFilters = () => {
    const values = formFilters.getFieldsValue();
    const filters: VideoRecordFiltersType = {};

    if(values.dates) {
      const initialDate = values.dates[0].startOf('day').format(INITIAL_DATETIME_FORMAT);
      const finalDate = values.dates[1].endOf('day').format(FINAL_DATETIME_FORMAT);

      filters.initialDate = initialDate;
      filters.finalDate = finalDate;
    }
    if (values.userId) {
      filters.userId = values.userId
    }

    onFinishFilter(filters);
  }

  return {
    users,
    disabledDate,
    handleFilters,
  };
}

export default useFiltersForm;