// Constants
import {userTypes} from '../types';
import {UserReducerType} from "../../../types/UserTypes";

const initialState = {
  params: {
    roles: []
  },
  usersResponse: {
    page: 1, pageSize: 10, rowCount: 0, collection: []
  }
};

const userReducer = (state = initialState, action: UserReducerType) => {
  switch (action.type) {
    case userTypes.GET_USER_PARAMS:
      return {
        ...state,
        params: action.params,
      };
    case userTypes.GET_USERS:
      return {
        ...state,
        usersResponse: action.usersResponse
      };
    default:
      return state;
  }
};

export default userReducer;