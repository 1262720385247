// Libraries
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

// Store
import {useAppDispatch} from "../../store/store";
import {getParams} from "../../store/redux/actions/videoRecordActions";
import * as videoRecordSelectors from '../../store/redux/selectors/videoRecordSelectors';

// Types
import {FiltersPaginationType} from "../../types/SharedTypes";
import {VideoRecordType, VideoRecordFiltersType} from "../../types/VideoRecordTypes";


const useVideoRecords = () => {
  const dispatch = useAppDispatch();
  const [videoRecordList, setVideoRecordList] = useState<VideoRecordType[]>([]);
  const [pagination, setPagination] = useState<FiltersPaginationType>({
    paginationVm: { page: 1, pageSize: 10 },
    filterVm: {}
  });
  const { users } = useSelector(videoRecordSelectors.paramsSelector)!;
  const { page, pageSize, rowCount, collection } = useSelector(videoRecordSelectors.videoRecordsResponseSelector)!;

  useEffect(() => {
    dispatch(getParams(pagination));
  }, [pagination]);

  useEffect(() => {
    const newVideoRecordList: VideoRecordType[] = [];

    collection.map((videoRecord: VideoRecordType) => {
      newVideoRecordList.push({
        ...videoRecord,
        userName: users.find(user => user.customId === videoRecord.userId)!.name
      })
    });

    setVideoRecordList(newVideoRecordList);
  }, [collection]);

  const handleSearch = (filters: VideoRecordFiltersType) => {
    setPagination({ paginationVm: { page: 1, pageSize: 10 }, filterVm: filters });
  }

  return {
    page,
    pageSize,
    rowCount,
    videoRecordList,
    filterValues: pagination.filterVm,
    setPagination,
    handleSearch,
  }
}

export default useVideoRecords;