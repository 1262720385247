// Libraries
import React, {ReactElement} from 'react';
import {Form} from 'antd';

// Constants and Functions
import {currencyFormat, currencyToNumber} from '../../utils/generalUtils';
const { Item } = Form;


type FormItemComponentType = {
  label?: string;
  name?: string;
  valuePropName?: string;
  required: boolean;
  withValueValidator?: boolean;
  className?: string;
  child: ReactElement;
  newRules?: object[];
  dependencies?: any[];
};
const FormItemComponent = (props: FormItemComponentType) => {
  const { label, name, valuePropName, required, withValueValidator, className, child, newRules, dependencies } = props;
  const rules: Array<object> = [{ required: required, message: 'Campo requerido' }];
  newRules?.map(rule => rules.push(rule));

return (
    withValueValidator
    ?
      <Item label={label} name={name} valuePropName={valuePropName}
            className={'mb-3 [&>div>div]:pb-1 [&>div>div>label]:text-sm [&>div>div>label]:font-medium ' +
              '[&>div>div>label]:text-grey-400 ' + className
            }
            rules={rules}
            getValueFromEvent={(e) => {
              const value = currencyToNumber(e.target.value);

              return isNaN(value) ? 0 : currencyFormat(value.toFixed(2).toString());
            }}>
        {child}
      </Item>
      :
      <Item label={label}
            name={name}
            valuePropName={valuePropName}
            dependencies={dependencies}
            className={'mb-3 [&>div>div]:pb-1 [&>div>div>label]:text-sm [&>div>div>label]:font-medium ' +
              '[&>div>div>label]:text-grey-400 ' + className
            }
            rules={rules}>
        {child}
      </Item>
  );
}

export default FormItemComponent;
