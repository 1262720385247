// Libraries
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {useSelector} from "react-redux";

// Types
import {VideoRecordFiltersType} from "../../../../types/VideoRecordTypes";

// Utils
import {DATE_TIME_FORMAT} from "../../../../utils/generalUtils";

// Store
import * as videoRecordSelectors from "../../../../store/redux/selectors/videoRecordSelectors";


type PropsType = {
  filterValues: VideoRecordFiltersType;
}
type TagListType = {
  name: string;
  value: string;
}
const useFilterTags = (props: PropsType) => {
  const { users } = useSelector(videoRecordSelectors.paramsSelector)!;
  const [tagList, setTagList] = useState<TagListType[]>([]);
  const {filterValues} = props;

  useEffect(() => {
    const newTags: TagListType[] = [];

    Object.keys(filterValues).map((filter) => {
      switch (filter) {
        case 'initialDate':
          newTags.push({
            name: 'Fecha inicial',
            value: dayjs(filterValues.initialDate).format(DATE_TIME_FORMAT)
          });
          break;
        case 'finalDate':
          newTags.push({
            name: 'Fechas final',
            value: dayjs(filterValues.finalDate).format(DATE_TIME_FORMAT)
          });
          break;
        case 'userId':
          newTags.push({
            name: 'Usuario',
            value: users.find(user => user.customId === filterValues.userId)!.name
          });
          break;
        default:
          break;
      }
    });

    setTagList(newTags);
  }, [filterValues]);

  return {
    tagList
  };
}

export default useFilterTags;