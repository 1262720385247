// Libraries
import React from 'react';

type CustomIconsType = {
  width: number,
  height: number,
  fill: string,
  className?: string
};

export const Logo = ({ width, height, fill, className }: CustomIconsType) => (
  <svg width={width} height={height} viewBox="0 0 171 86" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M41.2119 70.1171H46.3808C52.3857 70.1171 57.0657 64.8501 57.0181 58.8454C57.0134 58.2538 57.0112 57.6378 57.0112 56.9929V13.6912C57.0112 13.135 57.0128 12.5945 57.0163 12.0685C57.0564 5.98317 52.2353 0.566895 46.1498 0.566895H40.5432C41.0447 4.24503 41.2119 7.83958 41.2119 13.6912V20.6295C41.2119 21.7998 41.2119 22.5521 41.2955 23.806C35.7783 19.3756 30.9298 17.6201 24.1587 17.6201C17.304 17.6201 11.4524 19.8771 7.1891 24.1404C2.50782 28.9053 0 35.8436 0 44.5374C0 61.4234 9.19536 71.6218 24.4095 71.6218C31.4314 71.6218 35.6947 70.1171 41.2955 65.6031C41.2119 66.3554 41.2119 66.9406 41.2119 67.4421V70.1171ZM28.0876 30.41C35.6947 30.41 41.0447 36.2616 41.0447 44.3702C41.0447 52.646 35.6111 58.8319 28.1712 58.8319C25.0782 58.8319 22.0688 57.6616 20.0626 55.6554C17.5548 53.2311 16.2173 49.3858 16.2173 44.621C16.2173 35.8436 20.8149 30.41 28.0876 30.41Z" fill="#7DE2F5"/>
    <path d="M95.2972 37.0975C89.2785 37.2647 86.7706 37.3483 83.5105 37.6827C75.987 38.3514 70.3862 40.1069 66.1229 43.1163C62.1104 45.8749 60.0205 50.0546 60.0205 55.321C60.0205 59.9187 61.6088 63.9312 64.6182 66.6898C67.9619 69.7828 72.7268 71.2875 79.08 71.2875C85.9347 71.2875 90.616 69.8664 95.2972 66.2718V70.1171H111.514C111.096 66.3554 110.929 63.346 110.929 58.2468V35.6764C110.929 28.1529 108.589 23.5553 103.239 20.6295C99.9785 18.874 93.9597 17.7037 87.7738 17.7037C79.6651 17.7037 73.4792 19.292 68.9651 22.4685C65.4541 24.9764 63.6151 27.6514 61.9432 32.5834L77.9096 35.1748C79.1636 31.0787 82.0057 29.3232 87.3558 29.3232C92.873 29.3232 95.2972 31.1623 95.2972 35.1748V37.0975ZM95.2972 50.1382C95.2972 52.646 94.2105 54.903 92.2878 56.5749C90.0308 58.4976 86.2691 59.7515 82.8417 59.7515C78.4948 59.7515 75.987 57.996 75.987 54.9866C75.987 52.5624 77.8261 50.4725 80.8354 49.4694C84.2628 48.3827 87.8574 47.9647 95.2972 47.7975V50.1382Z" fill="#7DE2F5"/>
    <path d="M132.548 65.6031L130.793 69.1976C129.539 71.7054 127.198 73.1265 124.022 73.1265C121.848 73.1265 120.26 72.7085 117.501 71.2875L115.662 84.5789C120.26 85.582 123.436 86 127.115 86C136.644 86 142.663 82.4055 146.007 74.5476L165.15 30.3264C168.076 23.4717 168.076 23.4717 170.165 19.1248H152.026C151.524 22.0506 150.772 24.4748 148.431 30.2428L140.991 49.2186L133.3 29.9084C131.545 25.3943 130.458 21.7998 130.04 19.1248H111.9C112.987 21.2146 113.321 21.967 116.916 30.3264L132.548 65.6031Z" fill="#7DE2F5"/>
    <path d="M16.2545 11.7961C16.2545 0.650247 5.41825 -0.588184 0.000111985 0.185836C0.000111985 4.83006 0 25.7286 0.000111985 32.6948C2.32206 23.4065 11.6104 17.3692 16.2545 16.9048V11.7961Z" fill="#7DE2F5"/>
  </svg>
);
