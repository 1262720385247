// Libraries
import React from "react";
import {useSelector} from "react-redux";

// Store
import * as userSelectors from "../../../store/redux/selectors/userSelectors";


const useUserDrawer = () => {
  const { roles } = useSelector(userSelectors.paramsSelector)!;

  return {
    roles,
  }
}

export default useUserDrawer;
