// Libraries
import React from "react";
import {LockOutlined} from '@ant-design/icons';
import useResetPassword from "./useResetPassword";

// Components
import InputComponent from "../../subComponents/InputComponent";
import FormItemComponent from "../../subComponents/FormItemComponent";
import Account from "../Account";

// Utils
import {routes} from "../../../utils/routes";


const ResetPassword = () => {
  const {form, handleResetPassword} = useResetPassword();
  const {getFieldValue} = form;

  return (
    <Account buttonText={'Establecer nueva contraseña'}
             headerContent={
               <span>
                 Nueva contraseña en <span className={'font-bold text-aquamarine-100 md:text-grey-blue-600'}>Bday</span>
               </span>
             }
             form={form}
             formContent={
               <span>
                 <FormItemComponent name={'password'}
                                    label={'Nueva contraseña'}
                                    required={true}
                                    child={<InputComponent type={'password'}
                                                           placeHolder={'Digita tu nueva contraseña'}
                                                           prefix={<LockOutlined />} />}
                 />

                 <FormItemComponent name={'confirmPassword'}
                                    label={'Confirmación de nueva contraseña'}
                                    required={true}
                                    dependencies={['password']}
                                    newRules={[
                                      () => ({
                                        validator(_: any, value: any) {
                                          if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(new Error('Las contraseñas no coinciden'));
                                        },
                                      }),
                                    ]}
                                    child={<InputComponent type={'password'}
                                                           placeHolder={'Confirma la contraseña'}
                                                           prefix={<LockOutlined />} />}
                 />
               </span>
             }
             footerContent={
               <>
                 {'¿Ya recuerdas tu contraseña? '}
                 <a href={routes.account.login}
                    className={'text-secondaryLight font-bold hover:underline'}>
                   Inicia sesión
                 </a>
               </>
             }
             handleSubmit={handleResetPassword} />
  );
}

export default ResetPassword;