// Libraries
import React from "react";
import {Card} from "antd";
import useVideoRecords from "./useVideoRecords";

// Utils
import {tableColumns} from "../../utils/tableColumns";

// Components
import TableComponent from "../subComponents/TableComponent";
import Filters from "./filters/Filters";


const VideoRecords = () => {
  const {page, pageSize, rowCount, videoRecordList, filterValues, setPagination, handleSearch} = useVideoRecords();

  return (
    <Card title={"Videos"} bordered={false} className={'w-full'}>
      <Filters filterValues={filterValues}
               onFinishFilter={handleSearch} />

      <TableComponent pageSize={pageSize}
                      currentPage={page}
                      total={rowCount}
                      scrollX={900}
                      rowKey={'videoRecordId'}
                      dataSource={videoRecordList}
                      columns={tableColumns('videoRecords')}
                      onChangePage={(pagination) => setPagination({ filterVm: filterValues, paginationVm: pagination })} />
    </Card>
  );
}

export default VideoRecords;