// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import userServices from '../../../api/userServices';
import {userTypes} from "../types";

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';


// Types
import {PaginationType} from "../../../types/SharedTypes";
import {UserType} from "../../../types/UserTypes";


export const getParams = (pagination: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userServices.getParams()
    .then((response) => {
      dispatch({
        type: userTypes.GET_USER_PARAMS,
        params: response.data
      });
      dispatch(getAll(pagination));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const getAll = (pagination: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userServices.getAll(pagination)
    .then((response) => {
      dispatch(setLoading(false));
      dispatch({
        type: userTypes.GET_USERS,
        usersResponse: response.data
      });
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const add = (user: UserType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userServices.add(user)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getParams({ page: 1, pageSize: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}
export const update = (user: UserType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userServices.update(user)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getParams({ page: 1, pageSize: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data);
      dispatch(setLoading(false));
    })
}