// Libraries
import {useSelector} from 'react-redux';

// Store
import * as sessionSelectors from '../../../store/redux/selectors/accountSelectors';

// Utils and Constants
import {getLocalStorageItem, useScreenSizeValidator} from "../../../utils/generalUtils";

function Router() {
  const isLoggedIn = useSelector(sessionSelectors.isLoggedInSelector);
  const isSmallWindow = useScreenSizeValidator('(max-width: 767px)');
  const role = getLocalStorageItem('render_role') ?? '';

  return {
    isLoggedIn,
    isSmallWindow,
    role
  }
}

export default Router;