import React from 'react';
import {NavLink} from 'react-router-dom';
import {Col, Drawer, Layout, Menu, Row} from 'antd';
import {LogoutOutlined, MenuOutlined, UserOutlined} from '@ant-design/icons';
import useNavbar from "./useNavbar";

// Subcomponents
import ButtonComponent from '../../subComponents/ButtonComponent';

// Utils and Constants
import {routes} from '../../../utils/routes';
import {Logo} from "../../../assets/customIcons";
const {Header} = Layout;


const Navbar = () => {
  const {drawerVisible, currentRoute, isSmallWindow, handleRouteSelector, setNavItems, setDrawerVisible} = useNavbar();

  return (
    <Header className={`bg-grey-blue-600 shadow-md h-16 ${isSmallWindow ? 'px-12' : 'px-20'}`}>
      <Row>
        <Col xs={8} md={4} xl={2} xxl={1} className={`h-16 px-3 text-center flex`}>
          <NavLink className={"my-auto"} to={routes.root}>
            <Logo width={90} height={45} fill={''} />
          </NavLink>
        </Col>
        <Col xs={16} md={20} xl={22} xxl={23} className={'h-16'}>
          {isSmallWindow
            ?
            <Col className={'h-16 border-0 bg-grey-blue-600 text-white block text-right text-2xl'}>
              <MenuOutlined className={'h-16 w-14 [&>svg]:m-auto hover:bg-black-gray-900 cursor-pointer'}
                            onClick={() => setDrawerVisible(true)} />

              <Drawer placement={"right"} open={drawerVisible} closable={false} width={250}
                      onClose={() => setDrawerVisible(false)} className={'[&>div>div]:p-0'}
                      footer={
                        <p className={'p-5'}>
                          <ButtonComponent text={'Cerrar sesión'} icon={<LogoutOutlined />} className={'w-full ant-btn-primary'}
                                           onClick={() => handleRouteSelector('logout')} />
                        </p>
                      }>
                <p className={'text-center text-lg py-5'}>
                  <span>
                    <UserOutlined /> {'Usuario'}
                  </span>
                </p>
                {setNavItems().map(item =>
                  item.key !== "userName" &&
                  <NavLink to={item.key} key={item.key}
                           className={`${item.key === currentRoute ? '[&>p]:bg-white [&>p]:font-medium' +
                             '[&>p]:border-l-4 [&>p]:border-l-gray-900' : '' } hover:text-aquamarine-300`}>
                    <p className={"my-2.5 h-10 flex [^&^>span]:my-auto border-l-4 border-white hover:border-l-black-gray-900"}
                       onClick={() => handleRouteSelector(item.key)}>
                      <span>{item.label}</span>
                    </p>
                  </NavLink>
                )}
              </Drawer>
            </Col>
            :
            <Menu mode={"horizontal"} selectedKeys={[currentRoute]} items={setNavItems()}
                  className={'text-right h-16 w-full border-0 bg-grey-blue-600 inline-block ' +
                    '[&>li]:h-16 [&>li]:leading-[60px] [&>li]:mb-px [&>li]:after:border-0 [&>li]:text-white ' +
                    '[&>li:hover]:bg-grey-blue-500 ' +
                    '[&>li:not(.ant-menu-item-selected)>div:hover]:text-aquamarine-100 ' +
                    '[&>li:hover]:mb-px [&>li.ant-menu-item-active]:text-aquamarine-100 ' +
                    '[&>li.ant-menu-item-active]:after:border-0 [&>li.ant-menu-item-active]:mb-px ' +
                    '[&>li.ant-menu-item-active:hover]:after:border-0 [&>li.ant-menu-item-active:hover]:mb-px ' +
                    '[&>li.ant-menu-item-selected]:text-aquamarine-100 [&>li.ant-menu-item-selected]:border-0 ' +
                    '[&>li.ant-menu-submenu]:float-right [&>li.ant-menu-submenu]:flex [&>li.ant-menu-submenu>div]:m-auto'
                  }
                  onClick={(item) => handleRouteSelector(item.key)}
            />
          }
        </Col>
      </Row>
    </Header>
  );
}

export default Navbar;